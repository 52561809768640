



























import { Component, Vue } from 'vue-property-decorator';
import { CMAPI as API } from '../lib/server.api';
import { EventBus, EventBusEvents } from '@/lib/event-bus.bus';

@Component({})
export default class ConfirmRegistration extends Vue {

    public success: boolean = false;
    private embTag: string[] | undefined = undefined;

    public async created() {
        this.embTag = this.$route.query.cmtag ? (<string>this.$route.query.cmtag).split(',') : undefined;
        (window as any).gtag('config', 'UA-123331887-12', { page_path: '/Confirm'});
        try {
            this.success = await API.confirmRegistration(<string>this.$route.query.t, <string>this.$route.query.cmtag, <string>this.$route.query.hop);
            let label = 'SSO';
            if(this._hasTag()) {
              label += '_' + this._getTagString();
            }
            (window as any).gtag('event', 'sign_up_confirm', { event_category: 'engagement', event_label: label });
        } catch (e) {
            this.success = false;
        }
    }

    private _hasTag(): boolean {
      return (this.embTag !== undefined && ( Array.isArray(this.embTag) && this.embTag.length > 0 ));
    }

    private _getTagString(): string {
      if(this.embTag && (Array.isArray(this.embTag) && this.embTag.length > 0)) {
        return this.embTag[0];
      }
      return '';
    }
}
