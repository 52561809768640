import axios from 'axios';
import { CMPolicyRule } from '@/models/cm-policy-rule.model';
import { set as setCookie, get as getCookie, remove as removeCookie } from 'es-cookie';

const ax = axios.create({
    baseURL: <any>process.env.VUE_APP_SSO_API + '/api',
    headers: {
        'Content-Type': 'application/json'
    },
});

export interface IConf {
    sso_url: string;
    profile_url: string;
}

export class CMAPI {

    public static async ping(): Promise<string> {
        const res = await ax.get<CMResponse>('/ping');
        return res.data.data;
    }

    public static async conf(): Promise<IConf> {
        const res = await ax.get<CMResponse>('/conf');
        return res.data.data as IConf;
    }

    public static async getPolicies(): Promise<CMPolicyRule[]> {
        const policies = new Array();
        try {
            const res = await ax.get('/register/user/policies');
            res.data.data.forEach((element) => {
                policies.push(new CMPolicyRule().fromData(element));
            });
            return policies;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    public static async login(uname: string, pass: string, dest: string, cmtag?: string[]): Promise<any> {
        try {
            const res = await ax.post<CMResponse>('/login', { email: uname, password: pass, destination: dest, cmtag });
            const data = res.data.data;
            this._setToken(data.token);
            return data;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    public static async logout(): Promise<boolean> {
        try {
            const res = await ax.get<CMResponse>('/logout');
            this._deleteToken();
            return true;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    public static async register(uname: string, pass: string, confPass: string, consents: CMPolicyAgree[], cmtag?: string[], hop?: string, utm?: any): Promise<any> {
        if (!utm) {
            utm = {};
        }
        try {
            const res = await ax.post<CMResponse>('/register', { email: uname, password: pass, confirmPassword: confPass, policies: consents, cmtag, hop, ...utm });
            return res.data.data;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    public static async recoverPassword(email: string): Promise<boolean> {
        try {
            // tslint:disable-next-line:object-literal-shorthand
            const res = await ax.post<CMResponse>('/users/password/recover', { email: email });
            return res.data.success;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    public static async checkRecoverPassword(token: string): Promise<boolean> {
        try {
            // tslint:disable-next-line:object-literal-shorthand
            const res = await ax.post<CMResponse>('/users/password/check', { t: token });
            return res.data.success;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    public static async confirmRegistration(token: string, cmtag?: string, hop?: string): Promise<boolean> {
        try {
            // tslint:disable-next-line:object-literal-shorthand
            const res = await ax.post<CMResponse>('/register/confirm', { t: token, cmtag, hop });
            return res.data.success;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    public static async resetPassword(password: string, confirm: string): Promise<boolean> {
        try {
            // tslint:disable-next-line:object-literal-shorthand
            const res = await ax.post<CMResponse>('/users/password/reset', { password: password, confirmPassword: confirm }, { headers: { Authorization: 'Bearer ' + this._retrieveToken() } });
            return res.data.success;
        } catch (e) {
            throw this._handleError(e);
        }
    }

    private static _handleError(error): CMServerErrorList | Error {
        if (error.response) {
            if (error.response.status === 409) {
                return new DuplicatedEmailError('Email already exists');
            }
            if (error.response.status === 401) {
                this._deleteToken();
            }

            error = JSON.parse(JSON.stringify(error.response));
            if (this._checkTokenDelete(error.data.errors || [])) {
                this._deleteToken();
            }
            return {
                errors: error.data.errors,
                name: 'CMServerErrorList',
                message: 'Server Error'
            };
        } else if (error.request) {
            return new Error('Error on request => Server does not respond');
        } else {
            return new Error('Client error => ' + error.message);
        }
    }

    private static _checkTokenDelete(errors) {
        const tError = errors.findIndex((elem) => {
            return elem.name === CM_INVALID_TOKEN || elem.name === CM_EXPIRED_TOKEN;
        });
        return tError !== 1;
    }

    private static _deleteToken() {
        localStorage.removeItem('cmtkn');
        removeCookie('cmtkn');
    }

    private static _setToken(token) {
        localStorage.setItem('cmtkn', token);
        setCookie('cmtkn', token);
    }

    private static _retrieveToken(): string {
        let token;
        token = getCookie('cmtkn');
        if (!token) { token = localStorage.getItem('cmtkn'); }
        if (!token) { token = ''; }
        return token;
    }

}

export interface CMServerErrorList extends Error {
    errors: CMServerError[];
}

export interface CMServerError extends Error {
    name: string;
    message: string;
}

export interface CMPolicyAgree {
    id: string;
    agree: number;
}

export class DuplicatedEmailError extends Error { }

interface CMResponse {
    data: any;
    errors: any;
    success: boolean;
}

export const CM_INVALID_TOKEN = 'CMInvalidTokenError';
export const CM_EXPIRED_TOKEN = 'CMExpiredTokenError';
export const CM_DUPLICATED_EMAIL_ERROR = 'CMDuplicatedEmailError';
export const CM_DUPLICATED_EMAIL_SPEAKER_ERROR = 'CMDuplicatedEmailSpeakerError';
export const CM_INVALID_EMAIL_ERROR = 'CMInvalidEmailError';
export const CM_INVALID_PASSWORD_ERROR = 'CMInvalidPasswordError';
export const CM_INVALID_CONFIRM_PASSWORD_ERROR = 'CMInvalidConfirmPasswordError';
export const CM_INVALID_REF_ID_ERROR = 'CMInvalidRefIdError';
export const CM_INVALID_CONSENTS_ERROR = 'CMInvalidConsentsError';
export const CM_INVALID_REQUIRED_CONSENTS_ERROR = 'CMInvalidRequiredConsentsError';
export const CM_INVALID_OPTIONAL_CONSENTS_ERROR = 'CMInvalidOptionalConsentsError';
export const CM_UNHANDLED_ERROR = 'CMUnhandledError';
export const CM_INVALID_LOGIN_DATA_ERROR = 'CMInvalidLoginDataError';
export const CM_INACTIVE_ACCOUNT_ERROR = 'CMInactiveAccountError';
export const CM_DB_ERROR = 'DBError';
export const CM_INVALID_EMAIL_OR_TOKEN_ERROR = 'CMInvalidEmailOrTokenError';
export const CM_PASSWORD_TOKEN_EXPIRED_ERROR = 'CMPasswordTokenExpiredError';
