














import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GoogleButton extends Vue {

    private auth2;

    mounted() {
        const gapi: any = (<any>window).gapi;
        let $this = this;
        gapi.load("auth2", function() {
            $this.auth2 = gapi.auth2.init({
                client_id: $this.clientId,
                cookiepolicy: "single_host_origin",
                ux_mode: "popup"
            });
            $this.auth2.attachClickHandler(
                $this.$refs.signInButton,
                {},
                $this.onSignIn,
                error => console.error(error)
            );
        });
    }

    async onSignIn(googleUser) {
        const id_token = googleUser.getAuthResponse().id_token;
        this.redirectUri.searchParams.append('code', id_token);
        window.location.assign(this.redirectUri.href);
    }

    get clientId() {
        return process.env.VUE_APP_GOOGLE_CLIENT_ID;
    }

    get redirectUri() {
        const redirectUri = new URL(
            process.env.VUE_APP_SSO_API + "/bounce/oauth/google"
        );
        if (this.$route.query.hop) {
            redirectUri.searchParams.append("hop", "" + this.$route.query.hop);
        }
        if (this.$route.query.cmtag) {
            redirectUri.searchParams.append(
                "cmtag",
                "" + this.$route.query.cmtag
            );
        }
        if (this.$route.query.utm_source) {
            redirectUri.searchParams.append(
                "utmSource",
                "" + this.$route.query.utm_source
            );
        }
        if (this.$route.query.utm_medium) {
            redirectUri.searchParams.append(
                "utmMedium",
                "" + this.$route.query.utm_medium
            );
        }
        if (this.$route.query.utm_campaign) {
            redirectUri.searchParams.append(
                "utmCampaign",
                "" + this.$route.query.utm_campaign
            );
        }
        if (this.$route.query.utm_url) {
            redirectUri.searchParams.append(
                "utmUrl",
                "" + this.$route.query.utm_url
            );
        }
        return redirectUri;
    }
}
