import '@babel/polyfill';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import router from './router';
// import VueMaterial from 'vue-material';
import BootstrapVue from 'bootstrap-vue';
import { MdCard, MdField, MdButton, MdProgress, MdCheckbox, MdRadio, MdDialog, MdDialogAlert } from 'vue-material/dist/components';
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import vueCustomElement from 'vue-custom-element';

// Vue.use(VueMaterial);
Vue.use(Vuex);
Vue.use(vueCustomElement);
Vue.use(BootstrapVue);
Vue.use(MdCard);
Vue.use(MdField);
Vue.use(MdButton);
Vue.use(MdProgress);
Vue.use(MdCheckbox);
Vue.use(MdRadio);
Vue.use(MdDialog);
Vue.use(MdDialogAlert);

Vue.config.productionTip = false;

const store = new Vuex.Store({
  state: {
    conf: {}
  },
  getters: {
    conf: (state) => {
      return state.conf;
    }
  },
  mutations: {
    updateConf: (state, conf) => {
      state.conf = conf;
    }
  }
});

new Vue({
  router,
  render: (h) => h(App),
  store
}).$mount('#app');

