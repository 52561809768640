








import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import { EventBus, EventBusEvents } from '@/lib/event-bus.bus';
import { CMAPI, IConf } from '@/lib/server.api';
@Component({
  components: {
    Modal
  }
})
export default class App extends Vue {

  public embedded: boolean = false;
  private conf: IConf = {sso_url:'', profile_url:''};


  public async created() {
    this.embedded = (this.$route.query.e)?true:false;
  }

  public async mounted() {
    this.conf = await CMAPI.conf();
    this.$store.commit('updateConf', this.conf);
    EventBus.$on(EventBusEvents.MODAL_SHOW, this._handleModalEvent);
    EventBus.$on(EventBusEvents.LOGIN_SUCCESS, this._handleLoginSuccess);
    EventBus.$on(EventBusEvents.REGISTER_SUCCESS, this._handleLoginSuccess);
  }

  private _handleModalEvent(content: string = '', confirmText: string = '') {
    (this.$refs.modal as Modal).showModal(content, confirmText);
  }

  private _handleLoginSuccess(data: any) {
    // if (!this._redirectAfterLogin()) {
      if (data.redirect && data.redirect !== '') {
        window.location.replace(data.redirect);
      } else {
        window.location.replace(this.conf.profile_url);
      }
    // }
  }

  private _redirectAfterLogin() {
    if (this.$route.query.hop) {
      window.location.replace(<string>this.$route.query.hop);
      return true;
    }
    return false;
  }
}
