
























import { Component, Prop, Vue } from "vue-property-decorator";
import GitHubButton from "@/components/SocialButtons/GitHubButton.vue";
import GoogleButton from "@/components/SocialButtons/GoogleButton.vue";

@Component({
    components: {
        GitHubButton,
        GoogleButton
    }
})
export default class SocialLogin extends Vue {

    get isProduction() {
        return process.env.NODE_ENV === 'production';
    }
}
