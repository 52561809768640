













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class GitHubButton extends Vue {
    get oauthLink() {
        const gitHubURL = new URL("https://github.com/login/oauth/authorize");
        gitHubURL.searchParams.append("scope", "user:email");
        gitHubURL.searchParams.append(
            "client_id",
            this.$store.state.conf.gh_client_id
        );
        const redirectUri = new URL(
            process.env.VUE_APP_SSO_API + "/bounce/oauth/github"
        );
        if (this.$route.query.hop) {
            redirectUri.searchParams.append("hop", "" + this.$route.query.hop);
        }
        if (this.$route.query.cmtag) {
            redirectUri.searchParams.append(
                "cmtag",
                "" + this.$route.query.cmtag
            );
        }
        if (this.$route.query.utm_source) {
            redirectUri.searchParams.append(
                "utmSource",
                "" + this.$route.query.utm_source
            );
        }
        if (this.$route.query.utm_medium) {
            redirectUri.searchParams.append(
                "utmMedium",
                "" + this.$route.query.utm_medium
            );
        }
        if (this.$route.query.utm_campaign) {
            redirectUri.searchParams.append(
                "utmCampaign",
                "" + this.$route.query.utm_campaign
            );
        }
        if (this.$route.query.utm_url) {
            redirectUri.searchParams.append(
                "utmUrl",
                "" + this.$route.query.utm_url
            );
        }
        gitHubURL.searchParams.append("redirect_uri", redirectUri.href);
        return gitHubURL.href;
    }
}
