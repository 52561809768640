


































































import { Component, Vue } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { CMAPI as API } from '../lib/server.api';
import { EventBus, EventBusEvents } from '@/lib/event-bus.bus';

@Component({
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
})
export default class ResetPasswordPage extends Vue {
  public password: string = '';
  public confirmPassword: string = '';
  public operating: boolean = true;

  public isChecked: boolean = true;
  public resetSuccess: boolean = false;

  public created() {
    (window as any).gtag('config', 'UA-123331887-12', { page_path: '/ResetPassword' });
  }

  public async mounted() {
    this._checkToken();
  }

  public getValidationClass(fieldName) {
    const field = this.$v[fieldName];
    if (field) {
      return {
        'md-invalid': (field.$invalid && field.$dirty)
      };
    }
  }

  public async reset(): Promise<void> {
    this.operating = true;
    this.$v.$touch();
    if (this.$v.$invalid) {
      this.operating = false;
      return;
    }
    try {
        const res = await API.resetPassword(this.password, this.confirmPassword);
        if (res) {
          this.resetSuccess = true;
          this.operating = false;
        } else {
          this.resetSuccess = false;
          this.operating = false;
        }
    } catch (e) {
      console.error(e);
    } finally {
      this.operating = false;
    }
  }

  private async _checkToken() {
    try {
      const res = await API.checkRecoverPassword(<string>this.$route.query.t);
      if (res) {
        this.operating = false;
        this.isChecked = true;
      } else {
        this.isChecked = false;
      }
    } catch(e) {
      this.isChecked = false;
    }
  }

}
